<template>
  <div
    ref="editor"
    contenteditable="true"
    @input="onInput"
    @blur="emitValue"
    @focus="storeCursor"
    class="editor"
  ></div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  watch: {
    value(newValue) {
      if (this.$refs.editor.innerHTML !== newValue) {
        this.$refs.editor.innerHTML = newValue;
        this.restoreCursor();
      }
    }
  },
  methods: {
    onInput(event) {
      this.emitValue(event);
    },
    emitValue(event) {
      const newValue = event.target.innerHTML;
      this.$emit('input', newValue);
    },
    storeCursor() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        this.cursorPosition = selection.getRangeAt(0);
      }
    },
    restoreCursor() {
      if (this.cursorPosition) {
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(this.cursorPosition);
      }
    }
  },
  mounted() {
    this.$refs.editor.innerHTML = this.value;
  },
  data() {
    return {
      cursorPosition: null
    };
  }
};
</script>

<style scoped>
.editor {
  border: 1px solid gray;
  font-size: 15px;
  width: 100%;
  height: 300px;
  margin: 0 auto;
  overflow-y: auto;
  padding: 10px;
  cursor: text;
  white-space: pre-wrap;
}
</style>
